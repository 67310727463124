


















import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { Component, Mixins, Watch } from 'vue-property-decorator';

@Component
export default class AppView extends Mixins(AppRedirectionMixin, GroupsMixin) {
  @Watch('groups')
  private onGroupsChange(): void {
    if (this.hasAppRole(this.appRoles.APP_MAINTENANCE_ALERT)) {
      this.$router.push({ name: 'AppMaintenance/Alerts' });
    } else if (this.hasAppRole(this.appRoles.APP_MAINTENANCE_GATEWAY)) {
      this.$router.push({ name: 'AppMaintenance/Gateways' });
    }
  }
}
